<template>
  <a-modal :width="600" :visible="visible" :wrap-class-name="$style.modalSync" :closable="false">
    <template>
      <h5 class="mt-2">
        Export Data Pesanan
      </h5>
      <div class="mt-4">
        <label>{{ isWarehouse ? 'Gudang' : 'Bisnis' }}</label>
        <a-select
          v-model="form.selectedBusiness"
          show-search
          :filter-option="filterOption"
          :show-arrow="!$route.query.business_id"
          :disabled="!!$route.query.business_id"
          class="w-100 select-antd-default filter"
        >
          <a-select-option value="" selected hidden>
            Pilih {{ isWarehouse ? 'gudang' : 'bisnis' }}
          </a-select-option>
          <a-select-option v-for="(item, index) in businessOptions" :key="index" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="mt-4">
        <label>Channel</label>
        <a-select
          v-model="form.selectedSalesChannel"
          mode="multiple"
          :max-tag-count="1"
          show-search
          :filter-option="filterOption"
          class="w-100 select-antd-default filter"
          :disabled="!!$route.query.sales_channel_id"
          :show-arrow="!!$route.query.sales_channel_id"
          placeholder="Pilih channel"
          @select="onSelectStateMovement"
          @deselect="onDeselectStateMovement"
        >
          <a-select-option value="" selected hidden>
            Pilih channel
          </a-select-option>
          <a-select-option v-for="(item, index) in options_channel" :key="index" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="mt-4">
        <label>Gudang</label>
        <a-select
          v-model="form.selectedWarehouse"
          show-search
          placeholder="Pilih gudang"
          :filter-option="filterOption"
          class="w-100 select-antd-default filter"
        >
          <a-select-option value="" selected hidden>
            Pilih gudang
          </a-select-option>
          <a-select-option v-for="(item, index) in options_warehouse" :key="index" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="mt-4">
        <label class="m-0">Tanggal Pemesanan</label>
        <a-range-picker
          class="mt-2 w-100"
          :ranges="ranges"
          :format="dateFormat"
          :value="[form.startDate, form.endDate]"
          :placeholder="[
            $t('dashboardContent.start_date'),
            $t('dashboardContent.end_date'),
          ]"
          :disabled-date="disabledDate"
          :disabled="isCalculatingData"
          :show-time="{ format: 'HH:mm:ss' }"
          allow-clear
          @change="onChangeDate"
        >
          <DateIcon slot="suffixIcon" style="color: #999999" />
        </a-range-picker>
        <p v-if="invalidDateRange" class="text-danger mt-2">
          Jarak maksimal tanggal yang dipilih adalah {{ maxDateRange }} hari.
        </p>
      </div>
      <div class="mt-4">
        <label>Status Pesanan</label>
        <a-select
          v-model="form.statusOrder"
          class="w-100 select-antd-default filter"
          :disabled="isCalculatingData"
        >
          <a-select-option value="" selected hidden>
            Pilih status pesanan
          </a-select-option>
          <a-select-option v-for="(item, index) in categoryState" :key="index" :value="index">
            {{ $t('order.filterState.' + item.title) }}
          </a-select-option>
        </a-select>
      </div>
    </template>
    <template #footer>
      <template>
        <div class="d-flex justify-content-between align-items-center">
          <div class="w-50">
            <p class="m-0 text-left">
              total sales data:
              <a-spin v-if="isCalculatingData" class="ml-2" />
              <strong v-else class="ml-1">{{ totalExportRowData }}</strong>
            </p>
          </div>
          <div class="w-50 align-self-end">
            <a-button key="back" class="mr-2" @click="typeof back !== 'undefined' && back(), resetData(), onClose()">
              Cancel
            </a-button>
            <a-button
              key="submit"
              :loading="isCalculatingData"
              :disabled="isCalculatingData || !form.startDate || !form.endDate || (!form.statusOrder && form.statusOrder !== 0) || !form.selectedBusiness || !form.selectedSalesChannel?.length || !totalExportRowData || invalidDateRange"
              type="primary"
              @click="onSubmitModal()"
            >
              Export Excel
            </a-button>
          </div>
        </div>
      </template>
    </template>
  </a-modal>
</template>

<script>
import getQueryParam from '@/utils/getQueryParam'
import DateIcon from '@/components/Icons/DateV2.vue'
import debounce from 'lodash/debounce'

export default {
  name: 'ModalExportExcel',
  components: {
    DateIcon,
  },
  mixins: [getQueryParam],
  props: {
    visible: Boolean,
    back: Function,
    onClose: Function,
    categoryState: Array,
    totalExportRowData: Number,
    warehouseData: Array,
  },
  data() {
    return {
      ranges: {
        'Hari ini': [this.$moment(), this.$moment()],
        'Bulan ini': [this.$moment().startOf('month'), this.$moment().endOf('month')],
        '7 Hari Terakhir': [this.$moment().subtract(7, 'days'), this.$moment()],
        '30 Hari Terakhir': [this.$moment().subtract(30, 'days'), this.$moment()],
      },
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      form: {
        startDate: '',
        endDate: '',
        statusOrder: '',
        selectedBusiness: '',
        selectedSalesChannel: undefined,
        selectedWarehouse: undefined,
      },
      isCalculatingData: false,
      totalRowData: 0,
      channelOptions: [],
      maxDateRange: 31,
      invalidDateRange: false,
    }
  },
  computed: {
    isWarehouse() {
      return this.$store.state.user.restriction_base === 'Warehouse'
    },
    integration() {
      return this.$store.state.integration
    },
    businessOptions() {
      let list = []
      const value = this.$store.state.user.restriction_base === 'Business'
        ? 'business_id'
        : 'warehouse_id'
      const label = this.$store.state.user.restriction_base === 'Business'
        ? 'business_name'
        : 'name'
      this.$store.state.user.businessList.forEach(business => {
        if(business.business_id !== 'all') {
          list.push({
            value: business[value],
            label: business[label],
          })
        }
      })
      return list
    },
    getSalesChannelCode() {
      const selectedChannel = this.channelOptions.find((obj) => obj.value === this.form.selectedSalesChannel)
      if (selectedChannel) {
        return selectedChannel.channelCode
      }
      return null
    },
    getWarehouseCode() {
      const selectedData = this.channelOptions.find((obj) => obj.value === this.form.selectedData)
      if (selectedData) {
        return selectedData.channelCode
      }
      return null
    },
    options_channel() {
      const listChannels = []
      this.$store.state?.channel?.saleschannel?.forEach(item => {
        const findChannel = this.$store.state.business.listChannels.find(channel => {
          return channel.status?.toLowerCase() === 'connected' && channel.sales_channel.code === item.code
        })
        if(findChannel?.id) {
          listChannels.push({
            value: item.id,
            label: item.name,
          })
        }
      })
      listChannels.unshift(
        {
          "label": "Semua Channel",
          "value": "ALL",
        },
      )
      return listChannels
    },
    options_warehouse() {
      const listWarehouses = []
      this.warehouseData?.forEach(item => {
        listWarehouses.push({
          value: item.id,
          label: item.name,
        })
      })
      listWarehouses.unshift(
        {
          "label": "Semua Gudang",
          "value": "ALL",
        },
      )
      return listWarehouses
    },
  },
  watch: {
    'visible': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (!!value) {
          this.form.selectedBusiness = this.$route.query.business_id ?? ''
          if (this.$route.query.sales_channel_id) {
            this.form.selectedSalesChannel = [this.$route.query.sales_channel_id] ?? []
          }
          this.form.startDate = this.$moment().subtract(30, 'days').startOf('day').format()
          this.form.endDate = this.$moment().endOf('day').format()
        }
      },
    },
    '$route.query.business_id': {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.form.selectedBusiness = value ?? ''
      },
    },
    'form': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value.startDate && value.endDate && !this.invalidDateRange && (value.statusOrder || value.statusOrder === 0) && value.selectedBusiness && value.selectedSalesChannel?.length && value.selectedWarehouse?.length) {
          this.isCalculatingData = true
          this.onCalculateRowData()
        }
      },
    },
    // TODO: watch for salesChannel
    // 'form.selectedChannel': {
    //   deep: true,
    //   immediate: true,
    //   handler: async function (value) {
    //     if (!!value ) {
    //       const { data: fetchlistChannel } =  await this.$store.dispatch('business/GETLISTCHANNEL', { business_id: value })
    //       const connectedListChannel = fetchlistChannel?.data || []
    //       this.channelOptions = connectedListChannel.map((obj) => (
    //         {
    //           value: obj.sales_channel.id,
    //           label: obj.title,
    //           channelCode: obj.sales_channel.code,
    //         }
    //       ))
    //       if (this.$route.query.sales_channel_id) {
    //         const splitChannel = this.$route.query.sales_channel_id.split(',')
    //         this.form.selectedSalesChannel = splitChannel.map((obj) => parseInt(obj))
    //         if (splitChannel.length === this.channelOptions.length) {
    //           this.form.selectedSalesChannel.unshift('ALL')
    //         }
    //       } else {
    //         this.form.selectedSalesChannel = []
    //       }
    //       this.channelOptions.unshift(
    //         {
    //           "label": "Semua Channel",
    //           "value": "ALL",
    //         },
    //       )
    //     } 
    //   },
    // },
    'totalExportRowData': {
      deep: true,
      handler: function (value) {
        if (value !== null) {
          this.isCalculatingData = false
        }
      },
    },
  },
  methods: {
    checkDateRange(dates) {
      return this.$moment(dates[1]).diff(this.$moment(dates[0]), 'days') >= this.maxDateRange;
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
    },
    to(url) {
      const route = typeof url === 'object' ? url : { path: url }
      route.query = {
        ...this.to.query,
        ...this.getQueryParam({ ...this.$route.query }),
        ...url.params?.query,
      }
      
      return route
    },
    onMinimizeNotification() {
      this.onClose()
      this.$store.commit('exportData/SET_STATE_EXPORT_DATA', {
        exportFileNotification: true,
      })
    },
    disabledDate(current) {
      // return (current && current > this.$moment().endOf('day')) || (current && current.valueOf() < this.$moment().subtract(31, 'day'))
      return (current && current > this.$moment().endOf('day'))
    },
    onSelectStateMovement(record) {
      if (record === 'ALL') {
        this.form.selectedSalesChannel = this.options_channel.map((obj) => obj.value)
      }
    },
    async onDeselectStateMovement(record) {
      if (record === 'ALL') {
        this.form.selectedSalesChannel = await []
      } else if (record !== 'ALL' && this.form.selectedSalesChannel.includes('ALL')) {
        const deselectAll = await this.form.selectedSalesChannel
        this.form.selectedSalesChannel = deselectAll.filter((obj) => !['ALL', record].includes(obj))
      }
    },
    onSelectWarehouse(record) {
      if (record === 'ALL') {
        this.form.selectedWarehouse = this.options_warehouse.map((obj) => obj.value)
      }
    },
    async onDeselectWarehouse(record) {
      if (record === 'ALL') {
        this.form.selectedWarehouse = await []
      } else if (record !== 'ALL' && this.form.selectedWarehouse.includes('ALL')) {
        const deselectAll = await this.form.selectedWarehouse
        this.form.selectedWarehouse = deselectAll.filter((obj) => !['ALL', record].includes(obj))
      }
    },
    onChangeDate(date) {
      this.form.startDate = date[0]
        ? this.$moment(date[0]).format(this.formatDate)
        : ''
      this.form.endDate = date[1]
        ? this.$moment(date[1]).format(this.formatDate)
        : ''
      // this.invalidDateRange = this.checkDateRange(date)
      this.invalidDateRange = false
    },
    onCalculateRowData: debounce(function() {
      const selectedData = {
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        statusOrder: this.categoryState.at(this.form.statusOrder),
        selectedBusiness: this.form.selectedBusiness,
        selectedSalesChannelId: this.form.selectedSalesChannel.filter((obj) => obj !== 'ALL'),
        selectedSalesChannelCode: this.getSalesChannelCode,
      }
      if (this.form.selectedWarehouse && this.form.selectedWarehouse !== 'ALL') {
        selectedData['selectedWarehouseId'] = this.form.selectedWarehouse
      }
      this.$emit('handleCheckTotalRowData', selectedData)
    }, 1000),
    onSubmitModal() {
      // const countDiffDate = this.$moment(this.form.endDate).diff(this.$moment(this.form.startDate), 'days')
      // if (countDiffDate > 31) {
      //   return this.$notification.error({
      //     message: 'Maximum date range for export data is 31 days.',
      //     description: `The date range you choose is currently ${countDiffDate} days`,
      //   })
      // }
      const selectedData = {
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        statusOrder: this.categoryState.at(this.form.statusOrder),
        selectedBusiness: this.form.selectedBusiness,
        selectedSalesChannelId: this.form.selectedSalesChannel.filter((obj) => obj !== 'ALL'),
        selectedSalesChannelCode: this.getSalesChannelCode,
      }
      if (this.form.selectedWarehouse && this.form.selectedWarehouse !== 'ALL') {
        selectedData['selectedWarehouseId'] = this.form.selectedWarehouse
      }
      this.$emit('handleSubmitModal', selectedData)
      this.resetData()
    },
    resetData() {
      this.form.startDate = ''
      this.form.selectedBusiness = ''
      this.form.endDate = ''
      this.form.statusOrder = ''
      this.form.selectedSalesChannel = []
      this.form.selectedWarehouse = []
    },
  },
}
</script>

<style lang="scss" module>
// @import './style.module.scss';
</style>
<style lang="scss">
.disclaimer-max-data {
  font-size: 12px;
}
</style>