<template>
  <div class="salesindex">
    <div class="position-relative">
      <div class="position-absolute mt-2 pbz-font body-md-bold">
        {{ $t('sideBar.sales') }}
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <a-input-search
          class="col-4"
          allow-clear
          size="large"
          :placeholder="$t('utils.search') + ' No Order'"
          :value="querySearch"
          :disabled="loadingList"
          @change="handleSearch"
        />
        <a-button
          size="large"
          icon="filter"
          class="ml-2"
          :disabled="loadingList"
          @click="onShowModalFilter"
        >
          {{ $t('utils.filter') }}
        </a-button>
        <a-button v-if="activeFilter.length > 1" size="large" class="ml-2" @click="resetFilter">
          {{ $t('utils.clearFilter') }}
        </a-button>
      </div>
    </div>
    <div class="d-flex flex-wrap justify-content-center mt-3 categories">
      <a-tag
        v-for="item in activeFilter"
        :key="item.value"
        :closable="item.name === 'date_from' && !$route.query.date_from ? false : true"
        class="filter-tag mb-2"
        @close="removeFilter(item)"
      >
        <b>{{ $t(`sales.${item.name}`) }}:</b> {{ item.label }}
      </a-tag>
    </div>

    <div class="d-flex justify-content-between align-items-start my-3">
      <div class="flex-grow-1">
        <a-button
          v-for="item in categoryState"
          :key="item.title"
          shape="round"
          :disabled="loadingList || activeCategories === item.title"
          :class="[
            'px-2 mb-1 mr-1',
            activeCategories === item.title && 'bg-white text-danger border-danger',
          ]"
          @click="changeCategorieState(item)"
        >
          {{ $t('order.filterState.' + item.title) }}
        </a-button>
      </div>

      <div class="d-flex align-items-center flexno">
        <b class="mr-2">
          {{ $t('order.sortBy') }}
        </b>
        <a-select
          style="width: 120px"
          :disabled="loadingList || !salesData.length"
          :value="sort_mode"
          @change="handleChangeSort"
        >
          <a-select-option v-for="sort in SORT_OPTIONS" :key="sort.value" :value="sort.value">
            {{ $t('order.' + sort.label) }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="d-flex justify-start align-items-center mt-4" style="gap: 10px">
      <a-button
        type="primary"
        :disabled="loadingList && $store.state.exportData.exportFileNotification"
        @click.prevent="showModalExportExcel = true"
      >
        {{ $t('warehouse.export_data') }}
      </a-button>
      <a-button
        v-if="['shopee_id', 'tokopedia_id', 'tiktok', 'lazada_id'].includes($route.query?.channel_code)"
        type="secondary"
        @click.prevent="showModalSyncOrderChannel = true"
      >
        <a-icon type="sync" />
        Sync Order
      </a-button>
      <a-button
        v-if="$route.meta.key.includes('sales-channel-kino') && tempSales.length"
        type="primary"
        class="ml-3"
        :disabled="loadingList"
        @click.prevent="handleSubmitSFA"
      >
        Resend SFA
      </a-button>
      <div class="ml-auto">
        <a-skeleton
          active
          :title="false"
          :loading="loadingList"
          :paragraph="{ rows: 1, width: 180 }"
          class="w-auto mb-0 skeleton-total-sales"
        >
          <h6>{{ $t('order.totalAllSales') }} {{ total_row }}</h6>
        </a-skeleton>
      </div>
    </div>

    <div class="table-responsive pb-2 mt-3" :style="{ maxHeight: screenHeight + 'px' }">
      <div
        class="container-fluid border rounded py-2 mx-0 mt-0"
        :style="{ minWidth: listWidth + 'px', position: 'sticky', top: 0, background: 'white', zIndex: 2 }"
      >
        <div class="row flex-nowrap row-calc">
          <div
            v-for="tr in trList"
            :key="tr"
            :class="[tr === 'orderNumber' ? orderCol : tr === 'itemTotal' ? 'col-10p' : 'col', 'text-truncate', tr === 'checkbox' ? 'w-8' : '']"
            style="font-size: 12px"
          >
            <a-checkbox
              v-if="$route.meta.key.includes('sales-channel-kino') && tr === 'checkbox' && total_box > 0"
              :indeterminate="indeterminate"
              :checked="checked_all"
              :disabled="loadingList"
              class="sales-checkbox"
              @change="checkboxSelectAllSubmitted"
            />
            <span v-else-if="tr !== 'checkbox'">
              {{ $t('order.' + tr) }}
            </span>
          </div>
          <div class="col px-1 text-truncate" style="font-size: 12px">
            Status
          </div>
          <div class="" style="width: 40px !important; position: sticky; right: 0; background: white;" />
        </div>
      </div>

      <LoadingListTable v-if="loadingList" class="my-2" />
      <template v-else>
        <ErrorInfo v-if="errorRows" :error-type="errorRows" />
        <template v-else>
          <template v-if="salesData.length">
            <List
              v-for="(list, index) in salesData"
              :key="list.id"
              :sales="list"
              :sales-num="index"
              :width="listWidth"
              :permission="permission"
              :order-length="orderLength"
              :temp="tempSales"
              :business-id="businessId"
              class="mt-2"
              @handleItemSubmitted="handleItemSubmitted"
              @handleSfaDetail="handleSfaDetail"
              @onClickCancelOrder="onClickCancelOrder"
              @onClickInternalCancelOrder="onClickInternalCancelOrder"
              @onClickAccepted="onClickAccepted"
              @onOpenCollapse="onOpenCollapse"
              @onCancelRequest="onCancelRequest"
            />
          </template>
          <EmptyInfo
            v-else
            class="py-5"
            :title="$t('order.empty_title')"
            :description="$t('order.empty_msg')"
          />
        </template>
      </template>
    </div>
    <div class="d-flex justify-start align-items-center mt-3">
      <Pagination
        v-if="!loadingList && salesData.length"
        class="text-right ml-auto"
        :total="total_row"
        :page-size="PAGE_SIZE"
        :total-length-per-page="salesData.length"
        :default-page="currentPage"
        @changePage="changePage"
      />
    </div>

    <FilterList
      :show-modal="showModalFilter"
      :order-period="orderPeriod"
      :load-warehouse="loadWarehouse"
      :load-channel="loadChannel"
      :options-channel="options_channel"
      :business-value="businessValue"
      :channel-value="channelValue"
      :warehouse-value="warehouseValue"
      :customer-value="customerValue"
      :courier-value="courierValue"
      @close="showModalFilter = false"
      @onFocusChannel="fetchChannel"
      @onBlurChannel="blurChannel"
      @onFocusWarehouse="fetchWarehouse"
      @onBlurWarehouse="onBlurWarehouse"
      @submit="submitFilter"
    />

    <ModalConfirm
      :visible="showModalAccepted"
      :loading="loadingAccept"
      icon="check-circle"
      :title="$t('order.accept_confirm_msg')"
      :ok-label="$t('utils.yes')"
      :cancel-label="$t('utils.cancel')"
      @ok="onAcceptOrder"
      @cancel="onCloseModalAccept"
    >
      <div
        class="ant-alert ant-alert-error ant-alert-with-description ant-alert-no-icon py-2 mb-2 text-left"
      >
        <b>{{ $t('order.orderNumber') }}</b> : {{ dataAccepted.order_number }}
      </div>
    </ModalConfirm>

    <ModalCancelOrder
      ref="refModalCancelOrder"
      :visible="showModalCancelOrder"
      :loading="loadingCancelOrder"
      :data="dataCancelOrder"
      @cancel="closeModalCancelOrder"
      @ok="onSubmitCancelOrder"
    />

    <ModalInternalCancelOrder
      :visible="showModalInternalCancelOrder"
      :data="dataInternalCancelOrder"
      :loading="loadingCancelOrder"
      @cancel="closeModalInternalCancelOrder"
      @ok="onSubmitInternalCancelOrder"
    />

    <ModalExportExcel
      :visible="showModalExportExcel"
      :on-close="onCloseModalExportExcel"
      :category-state="categoryState"
      :warehouse-data="warehouse_list"
      :total-export-row-data="totalExportSalesData"
      @handleSubmitModal="downloadExcel($event, true)"
      @handleCheckTotalRowData="countDataSales($event, false)"
    />

    <ModalSyncOrderChannel
      :is-loading="loadingSyncOrder"
      :visible="showModalSyncOrderChannel"
      :on-close="onCloseModalSyncOrderChannel"
      @handleSubmitModal="onSyncOrderForChannel"
    />

    <a-modal
      title="Detail SFA"
      :visible="sfaInfo.visible"
      :closable="false"
      :footer="null"
      :width="450"
    >
      <a-row :gutter="16">
        <a-col :span="8">
          {{ $t('order.orderNumber') }}
        </a-col>
        <a-col :span="16">
          : {{ sfaInfo.order_number }}
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mt-2">
        <a-col :span="8">
          Status
        </a-col>
        <a-col :span="16">
          : {{ $t('utils.failed') }}
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mt-2">
        <a-col :span="8">
          {{ $t('store_list_page.description') }}
        </a-col>
        <a-col :span="16">
          :
        </a-col>
        <a-col :span="24" class="mt-1">
          {{ sfaInfo.description }}
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mt-4">
        <a-col :span="24" class="text-right">
          <a-button type="primary" ghost @click="sfaInfo.visible = false">
            {{ $t('utils.close') }}
          </a-button>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { getSales } from '@/api/sales'
import { getKinoSfa, resendKinoSfa } from '@/api/sfa'
import { onSyncOrderChannel, checkStatusSyncOrderChannel, processingOrder, processingOrderState, cancelOrderState, internalCancelOrderState } from '@/api/channels/index'
import { countSales, downloadSales } from '@/api/dashboard'
import FilterList from '@/components/Sales/Form/Filter/index-v2.vue'
import List from '@/components/Sales/List/index.vue'
import ModalConfirm from '@/components/Modal/ModalConfirm.vue'
import ModalCancelOrder from '@/components/Sales/Modal/ModalCancelOrder'
import ModalInternalCancelOrder from '@/components/Sales/Modal/ModalInternalCancelOrder'
import Pagination from '@/components/Pagination/index.vue'
import LoadingListTable from '@/components/Loading/ListTable'
import ErrorInfo from '@/components/ErrorInfo'
import EmptyInfo from '@/components/EmptyInfo'
import debounce from 'lodash/debounce'
import ChannelsConstants from '@/constants/channels'
import ModalExportExcel from '@/views/sales/ModalExportExcel.vue'
// import XLSX from 'xlsx';
// import { useCurrency } from '@/composable/useCurrency'
import ModalSyncOrderChannel from '@/views/sales/ModalSyncOrderChannel.vue'

import isEqual from 'lodash/isEqual'
import { saveAs } from 'file-saver'

// import { is } from 'vee-validate/dist/rules'
// const { format } = useCurrency()

const LIST_CHANNEL_OFFLINE = [
  "b2b_offline",
  "offline",
  "zalora_id",
  "external_web",
  "whatsapp_id",
  "shopify_id",
]

const setTableWidth = () => {
  const { width } = window.screen
  return width < 821 ? 1190 : width - 343
}

export default {
  components: {
    FilterList,
    List,
    ModalConfirm,
    ModalCancelOrder,
    ModalInternalCancelOrder,
    LoadingListTable,
    EmptyInfo,
    ErrorInfo,
    Pagination,
    ModalExportExcel,
    ModalSyncOrderChannel,
  },
  data() {
    return {
      SORT_OPTIONS: [
        { value: 'DESC', label: 'sortByNewest' },
        { value: 'ASC', label: 'sortByOldest' },
      ],
      categoryState: [
        { value: 'all', title: 'all' },
        { value: 'VERIFIED', title: 'verified' },
        { value: 'SUBMITTED', title: 'new' }, // old CREATED
        { value: 'CANCELREQUESTED', title: 'cancelrequested' },
        // { value: 'VERIFIED', title: 'Confirmed' },
        { value: 'ACCEPTED', title: 'onprocess', shipping_states: 'CREATED,BOOKED' },
        { value: 'ACCEPTED', title: 'shipped', shipping_states: 'SHIPPED' }, // value: 'SHIPPED',
        // { value: 'in_packing', title: 'In Process' },
        { value: 'ACCEPTED', title: 'send', shipping_states: 'DELIVERED' }, // value: 'DELIVERED', | old COMPLETED
        { value: 'COMPLETED', title: 'completed' },
        { value: 'CANCELED,CANCELEDPAYMENTEXPIRED,REJECTED', title: 'canceled' }, // value: 'CANCELED' | 'CANCELLED'
        // { value: 'REJECTED', title: 'rejected' }, // Pembatalan pesanan
      ],
      PAGE_SIZE: 20,
      total_row: 0,
      total_box: 0,
      orderLength: 0,
      showModalFilter: false,
      id: [],
      warehouse_list: [],
      querySearch: '',
      errorRows: false,
      salesData: [],
      tempSales: [],
      loadingList: true,
      loadChannel: true,
      loadWarehouse: true,
      customerValue: '',
      courierValue: '',
      businessValue: [],
      channelValue: [],
      warehouseValue: [],
      orderPeriod: ['', ''],
      showModalCancelOrder: false,
      showModalInternalCancelOrder: false,
      loadingCancelOrder: false,
      dataCancelOrder: {},
      dataAccepted: {},
      dataInternalCancelOrder: {},
      showModalAccepted: false,
      loadingAccept: false,
      controller: null,
      listWidth: setTableWidth(),
      showModalExportExcel: false,
      loadingExport: false,
      indeterminate: false,
      checked_all: false,
      sfaInfo: {
        visible: false,
        order_number: '',
        description: '',
      },
      totalExportSalesData: null,
      showModalSyncOrderChannel: false,
      loadingSyncOrder: false,
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    trList() {
      const tr = ['orderDate', 'orderNumber', 'business', 'channel', 'storeName', 'customer', 'itemTotal', 'priceTotal']
      return this.$route.meta.key.includes('sales-channel-kino')
        ? ['checkbox'].concat(tr)
        : tr
    },
    locale() {
      return this.$store.state?.settings?.locale || 'id-ID'
    },
    channel() {
      return this.$store.state.channel
    },
    currentPage() {
      return +this.$route.query.page || 1
    },
    sort_mode() {
      return this.$route.query.sort_mode || 'DESC'
    },
    activeCategories() {
      const query = this.$route.query
      const salesState = query.filter_category // query.sales_state || query.shipping_states || 
      if (!salesState) return 'all'
      return this.categoryState.find(item => item.title === salesState)?.title || 'all'
    },
    options_channel() {
      const listChannels = []
      this.$store.state?.channel?.saleschannel?.forEach(item => {
        const findChannel = this.$store.state.business.listChannels.find(channel => {
          return channel.status?.toLowerCase() === 'connected' && channel.sales_channel.code === item.code
        })
        if(findChannel?.id) {
          listChannels.push({
            value: item.id,
            label: item.name,
          })
        }
      })
      return listChannels
    },
    activeFilter() {
      let result = []
      const { date_from, date_until, sales_channel_id, warehouse_id, business_id_sales, customer_name, courier_name } = this.$route.query
      const dateFormat = 'DD MMM YYYY HH:mm:ss'

      if (date_from !== 'all' && date_until !== 'all') {
        const date = date_from && date_until
          ? this.$moment(date_from).format(dateFormat) + ' - ' + this.$moment(date_until).format(dateFormat)
          : this.$moment(this.orderPeriod[0]).format(dateFormat) + ' - ' + this.$moment(this.orderPeriod[1]).format(dateFormat)
        
        result.push({
          value: date,
          label: date,
          name: 'date_from',
        })
      }

      if(business_id_sales) {
        const businessParam = business_id_sales.split(',')
        businessParam.forEach(business => {
          const findBusiness = this.$store.state.user.businessList.find(item => {
            return item.business_id !== 'all' && item.business_id == business
          })
          if(findBusiness?.business_id) {
            result.push({
              value: findBusiness.business_id,
              label: findBusiness.business_name || findBusiness.name,
              name: 'business_id_sales',
            })
          }
        })
      }

      if(this.$route.path === '/sales' && sales_channel_id) {
        const channelParam = sales_channel_id.split(',')
        channelParam.forEach(channel => {
          const findChannel = this.channel.saleschannel.find(item => item.id == channel)
          if(findChannel?.id) {
            result.push({
              value: findChannel.id,
              label: findChannel.name,
              name: 'sales_channel_id',
            })
          }
        })
      }

      if (this.$store.state.user.restriction_base !== 'Warehouse' && warehouse_id) {
        const warehouseParam = warehouse_id.split(',')
        warehouseParam.forEach(warehouse => {
          const findWarehouse = this.warehouse_list.find(item => item.id === warehouse)
          if(findWarehouse?.id) {
            result.push({
              value: findWarehouse.id,
              label: findWarehouse.name,
              name: 'warehouse_id',
            })
          }
        })
      }

      if(customer_name) {
        result.push({
          value: customer_name,
          label: customer_name,
          name: 'customer_name',
        })
      }

      if(courier_name) {
        result.push({
          value: courier_name,
          label: courier_name,
          name: 'courier_name',
        })
      }
      
      return result
    },
    getUserData() {
      return this.$store.state?.user || {}
    },
    dataWarehouse() {
      return this.$store.state?.warehouse?.warehouse?.data || []
    },
    screenHeight() {
      return ((screen.height - 900) * 0.53) + (screen.height * 0.53)
    },
    permission() {
      let key = this.$route.meta.key
      if(this.$route.meta.key.includes('-:id')) key = key.replace('-:id', '')
      return this.$store.getters['user/can'](key)
    },
    listChannels() {
      const channel = this.$store.state.business.listChannels.find(item => item.sales_channel?.code === 'distributor_redeem')
      return channel
    },
    orderCol() {
      return 'col'
      // return this.orderLength < 16
      //   ? 'col-2'
      //   : this.orderLength > 15 && this.orderLength < 20
      //   ? 'col-2'
      //   : 'col-3'
    },
  },
  watch: {
    '$route.query'() {
      this.getData()
    },
    '$route.query.workspace_id'() {
      this.warehouse_list = []
      this.loadChannel = true
      this.loadWarehouse = true
      this.fetchChannel()
      this.fetchWarehouse()
    },
    '$route.query.channel_code'() {
      this.resetFilter()
    },
    businessId() {
      this.warehouse_list = []
      this.loadChannel = true
      this.loadWarehouse = true
      this.getData()
      this.fetchChannel()
      this.fetchWarehouse()
    },
    listChannels() {
      this.getData()
    },
  },
  mounted() {
    this.getData()
    this.fetchWarehouse()
    this.fetchChannel()
  },
  beforeDestroy() {
    // cancel the request
    if (this.controller) {
      this.controller.abort()
      this.controller = null
    }
  },
  methods: {
    textDiscountFrom(data) {
      if (data === 'channel') {
        return this.sales?.channel?.sales_channel?.name || 'Channel'
      } else if (data === 'seller'){
        return this.sales?.business?.business_title || 'Seller'
      }
      return data
    },
    wordingSalesState(sales_state) {
      if (!sales_state) {
        return null
      }
      switch (sales_state.toLowerCase()) {
        case 'verified':
          return 'verified'
        case 'submitted':
        case 'created':
          return 'new'
        case 'accepted':
          return 'onprocess'
        case 'shipped':
          return 'shipped'
        case 'delivered':
          return 'send'
        case 'completed':
          return 'completed'
        case 'canceled':
        case 'cancelled':
        case 'rejected':
        case 'canceledpaymentexpired':
          return 'canceled'
        case 'cancelrequested':
          return 'cancelrequested'
        default:
          return null
      }
    },
    async countDataSales(data, isExport) {
      const { value  } = data?.statusOrder
      const { query, params } = this.$route
      const { workspace_id } = query
      const filteredData = data
      let channelId = +params.id || undefined

      this.totalExportSalesData = null
      const selectedBusinessId = this.$store.state.user.restriction_base === 'Warehouse' ? this.businessId : filteredData?.selectedBusiness
      await countSales({
        workspace_id,
        business_id: selectedBusinessId,
        sales_channel_id: filteredData?.selectedSalesChannelId.join(','),
        sales_state: value === 'all' || ['SHIPPED', 'DELIVERED'].includes(value) ? undefined : value,
        channel_id: channelId,
        date_from: this.$moment(filteredData?.startDate).add(7, 'hours').toISOString(),
        date_until: this.$moment(filteredData?.endDate).add(7, 'hours').toISOString(),
        warehouse_id: filteredData?.selectedWarehouseId,
      })
      .then(response => this.totalExportSalesData = +response?.data || 0)
      .catch(error => {
        console.error(error)
        if(error.response.data.data === null) {
          this.$notification.error({
            message: 'Data Kosong',
            description: 'Tidak ada data berdasarkan filter tersebut.',
          })
        }
      })
    },
    async downloadExcel(data, isExport, page = 0) {
      const { value  } = data?.statusOrder
      const { query, params } = this.$route
      const { workspace_id } = query
      const filteredData = data
      let channelId = +params.id || undefined
      const selectedBusinessId = this.$store.state.user.restriction_base === 'Warehouse' ? this.businessId : filteredData?.selectedBusiness

      this.showModalExportExcel = false
      this.$store.commit('exportData/SET_STATE_EXPORT_DATA', {
        showModalExportSales: true,
        loadingExport: true,
      })

      await downloadSales({
        workspace_id,
        business_id: selectedBusinessId,
        sales_channel_id: filteredData?.selectedSalesChannelId.join(','),
        sales_state: value === 'all' || ['SHIPPED', 'DELIVERED'].includes(value) ? undefined : value,
        channel_id: channelId,
        date_from: this.$moment(filteredData?.startDate).add(7, 'hours').toISOString(),
        date_until: this.$moment(filteredData?.endDate).add(7, 'hours').toISOString(),
        warehouse_id: filteredData?.selectedWarehouseId,
        page,
      })
      .then(async response => {
        const length = Math.ceil(this.totalExportSalesData / 5000) - 1
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        saveAs(blob, `salesdata_${channelId ? 'channel_' : ''}${this.$moment(filteredData?.startDate).format('DDMMYYYY')}_${this.$moment(filteredData?.endDate).format('DDMMYYYY')}${page > 0 ? '_' + (page + 1) : ''}.xlsx`)

        if(page >= length) {
          this.totalExportSalesData = null
          this.$store.commit('exportData/SET_STATE_EXPORT_DATA', {
            exportFileNotification: false,
            showModalExportSales: false,
            loadingExport: false,
          })
        } else {
          await this.downloadExcel(data, isExport, page + 1)
        }
      })
      .catch(error => {
        console.error(error)
        this.totalExportSalesData = null
        this.$store.commit('exportData/SET_STATE_EXPORT_DATA', {
          exportFileNotification: false,
          showModalExportSales: false,
          loadingExport: false,
        })
        this.$notification.error({
          message: 'Proses export gagal',
          description: 'Data melebihi kapasitas server, silahkan pilih tanggal kembali',
        })
      })
    },
    onCloseModalExportExcel() {
      this.showModalExportExcel = false
      this.totalExportSalesData = null
    },
    onCloseModalSyncOrderChannel() {
      this.showModalSyncOrderChannel = false
    },
    onClickAccepted(val) {
      this.dataAccepted = val
      this.showModalAccepted = true
    },
    async onAcceptOrder() {
      let business_id = this.$route.query.business_id
      if (!business_id) {
        business_id = this.$store.state.user?.businessList?.find(
          val => val.warehouse_id === this.$route.query?.warehouse_id,
        )?.business_id
      }

      this.loadingAccept = true
      const channel_code = this.dataAccepted.channel?.code || this.dataAccepted.channel?.channel_code
      if (ChannelsConstants.includes(channel_code)) {
        await processingOrder({
          business_id: this.dataAccepted.business.business_id || business_id || this.businessId,
          processType: 'state',
          id: this.dataAccepted.id,
          channel_id: this.dataAccepted.channel?.id,
          channel_code: channel_code === 'distributor_redeem'
            ? 'distributor'
            : channel_code,
          new_status: 'ACCEPTED',
          order_number: this.dataAccepted.order_number,
        })
        .then(res => {
          this.$message.success(res?.data?.message || 'Successfully updated state')
          this.onCloseModalAccept()
          this.getData()
        })
        .catch(err => {
          this.$message.error(err?.response?.data?.message || err.message)
        })
        .finally(() => {
          this.loadingAccept = false
        })
      } else {
        await processingOrderState({
          business_id: this.dataAccepted.business.business_id || business_id || this.businessId,
          order_id: this.dataAccepted.id,
          order_number: this.dataAccepted.order_number,
          data: {
            new_status: 'ACCEPTED',
            reason_code: '',
            reason: '',
            oos_line_item_ids: [],
            channel: {
              id: this.dataAccepted.channel?.id,
              code: this.dataAccepted.channel?.code,
            },
          },
        })
        .then(res => {
          this.$message.success(res?.data?.message || 'Successfully updated state')
          this.onCloseModalAccept()
          this.getData()
        })
        .catch(err => {
          this.$message.error(err?.response?.data?.message || err.message)
        })
        .finally(() => {
          this.loadingAccept = false
        })
      }
    },
    onCloseModalAccept() {
      this.showModalAccepted = false
      this.dataAccepted = {}
    },
    onClickCancelOrder(val) {
      this.dataCancelOrder = val
      this.showModalCancelOrder = true
    },
    onClickInternalCancelOrder(val) {
      this.dataInternalCancelOrder = val
      this.showModalInternalCancelOrder = true
    },
    async onCancelRequest(val) {
      this.loadingList = true
      if (ChannelsConstants.includes(val.channel?.code)) {
        await processingOrder({
          business_id: val.business.business_id || this.$route.query.business_id || this.businessId,
          processType: 'reject-cancellation-request',
          id: val.id,
          channel_code: val.channel?.code === 'distributor_redeem'
            ? 'distributor'
            : val.channel?.code,
          new_status: 'CANCELED', // CANCELLED
          order_number: val.order_number,
        })
        .then(res => {
          this.$message.success(res?.data?.message || 'Successfully reject cancellation')
          this.getData()
        })
        .catch(err => {
          this.$message.error(err?.response?.data?.message || err.message)
        })
        .finally(() => {
          this.loadingList = false
        })
      } else {
        await processingOrderState({
          business_id: val.business.business_id || this.$route.query.business_id || this.businessId,
          order_id: val.id,
          order_number: val.order_number,
          data: {
            new_status: 'CANCELED',
            reason_code: '',
            reason: '',
            oos_line_item_ids: [],
            channel: {
              id: this.dataAccepted.channel?.id,
              code: this.dataAccepted.channel?.code,
            },
          },
        })
        .then(res => {
          this.$message.success(res?.data?.message || 'Successfully updated state')
          this.onCloseModalAccept()
          this.getData()
        })
        .catch(err => {
          this.$message.error(err?.response?.data?.message || err.message)
        })
        .finally(() => {
          this.loadingAccept = false
        })
      }
    },
    async onSubmitCancelOrder(values) {
      this.loadingCancelOrder = true

      if (ChannelsConstants.includes(this.dataCancelOrder.channel?.code)) {
        await processingOrder({
          business_id: this.dataCancelOrder.business.business_id || this.$route.query.business_id || this.businessId,
          processType: 'cancel',
          id: this.dataCancelOrder.id,
          channel_code: this.dataCancelOrder.channel?.code === 'distributor_redeem'
            ? 'distributor'
            : this.dataCancelOrder.channel?.code,
          new_status: 'CANCELED', // CANCELLED
          order_number: this.dataCancelOrder.order_number,
          ...values,
        })
        .then(res => {
          this.$message.success(res?.data?.message || 'Successfully cancel order')
          // Reset form after success
          // const modalCancelOrder = this.$refs.refModalCancelOrder?.form
          // modalCancelOrder && modalCancelOrder.resetFields()
          this.closeModalCancelOrder()
          this.getData()
        })
        .catch(err => {
          this.$message.error(err?.response?.data?.message || err.message)
        })
        .finally(() => {
          this.loadingCancelOrder = false
        })
      } else if (LIST_CHANNEL_OFFLINE.includes(this.dataCancelOrder.channel?.code)) {
        cancelOrderState({
          business_id: this.dataCancelOrder.business.business_id || this.$route.query.business_id,
          order_id: this.dataCancelOrder.id,
          order_number: this.dataCancelOrder.order_number,
          data: {
            new_status: 'CANCELED',
            reason_code: index_reason_code,
            reason: values?.reason,
            channel: {
              id: this.dataCancelOrder?.channel?.id,
              code: this.dataCancelOrder?.channel?.code,
              name: this.dataCancelOrder?.channel?.name,
              sales_channel_id: this.dataCancelOrder?.channel?.sales_channel?.id,
            },
          },
        })
        .then(res => {
          this.$message.success(res?.data?.message || 'Successfully updated state')
          this.closeModalCancelOrder()
          this.getData()
        })
        .catch(err => {
          this.$message.error(err?.response?.data?.message || err.message)
        })
        .finally(() => {
          this.loadingCancelOrder = false
        })
      } else {
        await processingOrderState({
          business_id: this.dataCancelOrder.business.business_id || this.$route.query.business_id || this.businessId,
          order_id: this.dataCancelOrder.id,
          order_number: this.dataCancelOrder.order_number,
          data: {
            new_status: 'CANCELED',
            reason_code: '',
            reason: '',
            oos_line_item_ids: [],
            channel: {
              id: this.dataAccepted.channel?.id,
              code: this.dataAccepted.channel?.code,
            },
          },
        })
        .then(res => {
          this.$message.success(res?.data?.message || 'Successfully updated state')
          this.closeModalCancelOrder()
          this.getData()
        })
        .catch(err => {
          this.$message.error(err?.response?.data?.message || err.message)
        })
        .finally(() => {
          this.loadingCancelOrder = false
        })
      }
    },
    onSubmitInternalCancelOrder(values) {
      this.loadingCancelOrder = true
      internalCancelOrderState({
        data: [values],
        business_id: this.dataInternalCancelOrder.business.business_id || this.$route.query.business_id || this.businessId,
      })
        .then(({ data }) => {
          if (data?.success?.length > 0) {
            this.$notification.success({
              message: 'Berhasil',
              description: 'Order berhasil dibatalkan',
            })
          }
          if (data?.failed?.length > 0) {
            this.$notification.success({
              message: 'Gagal',
              description: 'Terjadi kesalahan, gagal membatalkan order',
            })
          }
          this.closeModalInternalCancelOrder()
          this.getData()
        })
        .catch(err => {
          this.$notification.success({
            message: 'Gagal',
            description: 'Terjadi kesalahan, gagal membatalkan order',
          })
          console.error(err)
        })
        .finally(() => {
          this.loadingCancelOrder = false
        })
    },
    closeModalCancelOrder() {
      this.showModalCancelOrder = false
      this.dataCancelOrder = {}
    },
    closeModalInternalCancelOrder() {
      this.showModalInternalCancelOrder = false
      this.dataInternalCancelOrder = {}
    },
    setDefaultOrderPeriod() {
      return [this.$moment().subtract(30, 'days').startOf('day').format(), this.$moment().endOf('day').format()]
    },
    handleChangeSort(sort_mode) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$router.push({ query: { ...this.$route.query, sort_mode } })
      }, 500)
    },
    handleSearch(e) {
      const value = e.target.value
      this.querySearch = value
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const { path, query } = this.$route
        const { workspace_id, business_id, sales_channel_id, ...rest } = query
        const valTrim = value.trim()
        const defaultPeriod = this.setDefaultOrderPeriod()
        this.$router.push({
          query: {
            ...rest,
            workspace_id,
            business_id: business_id || this.businessId,
            // NOTES: set sales_channel_id from query params only page '/sales-channel' | path === '/sales-channel'
            sales_channel_id:
              path?.startsWith('/sales-channel') && sales_channel_id ? sales_channel_id : undefined,
            page: undefined,
            q: valTrim.length ? valTrim : undefined,
            date_from: valTrim.length ? 'all' : this.$moment(defaultPeriod[0]).format(),
            date_until: valTrim.length ? 'all' : this.$moment(defaultPeriod[1]).format(),
          },
        })
      }, 500)
    },
    submitFilter(value) {
      this.showModalFilter = false
      const { path, query } = this.$route
      const defaultPeriod = this.setDefaultOrderPeriod()
      let parseQuery = { workspace_id: query.workspace_id }

      if(query?.business_id) {
        parseQuery.business_id = query.business_id
      }

      if(value.business.length) {
        parseQuery.business_id_sales = value.business.join(',')
      }

      if(path === '/sales' && value.channel.length) {
        parseQuery.sales_channel_id = value.channel.join(',')
      } else if(path !== '/sales' && query?.sales_channel_id) {
        parseQuery.sales_channel_id = query.sales_channel_id
      }

      if(value.warehouse.length) {
        parseQuery.warehouse_id = value.warehouse.join(',')
      } else if(query?.warehouse_id && this.$store.state.user.restriction_base === 'Warehouse') {
        parseQuery.warehouse_id = query.warehouse_id
      }

      if(value.customer) {
        parseQuery.customer_name = value.customer
      }
      
      if(value.courier) {
        parseQuery.courier_name = value.courier
      }
      
      if(!isEqual(defaultPeriod, value.date)) {
        parseQuery = {
          ...parseQuery,
          date_from: value.date.length
            ? this.$moment(value.date[0]).format()
            : undefined,
          date_until: value.date.length
            ? this.$moment(value.date[1]).format()
            : undefined,
        }
      }

      if (!isEqual(parseQuery, query)) {
        this.$router.push({
          query: {
            ...query,
            ...parseQuery,
            page: undefined,
          },
        })
      }
    },
    resetFilter() {
      const { path, query } = this.$route
      let parseQuery = { workspace_id: query.workspace_id }

      if(query?.business_id) {
        parseQuery.business_id = query.business_id
      }

      if(query?.warehouse_id && this.$store.state.user.restriction_base === 'Warehouse') {
        parseQuery.warehouse_id = query.warehouse_id
      }

      // NOTES: set sales_channel_id from query params only page '/sales-channel' | path === '/sales-channel'
      if(path?.startsWith('/sales-channel')) {
        if(query?.channel_code) parseQuery.channel_code = query.channel_code
        if(query?.sales_channel_id) parseQuery.sales_channel_id = query.sales_channel_id
      }

      // Reset state
      this.customerValue = ''
      this.courierValue = ''
      this.businessValue = []
      this.channelValue = []
      this.warehouseValue = []
      this.orderPeriod = this.setDefaultOrderPeriod() // Reset to initial value

      if (!isEqual(parseQuery, query)) {
        this.$router.push({ query: parseQuery })
      }
    },
    removeFilter(item) {
      let query = {}
      if(item.name === 'date_from') {
        query = { date_from: undefined, date_until: undefined }
      } else {
        const tag = this.$route.query[item.name].split(',')
        const filteredTag = tag.filter(t => t != item.value)
        query = { [item.name]: filteredTag.length ? filteredTag.join(',') : undefined }
      }
      
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query,
        },
      })
    },
    changeCategorieState(state) {
      const { value, title, shipping_states } = state
      const UND = undefined
      this.$router.push({
        query: {
          ...this.$route.query,
          sales_state: value === 'all' || ['SHIPPED', 'DELIVERED'].includes(value) ? UND : value,
          shipping_states: ['SHIPPED', 'DELIVERED', 'ACCEPTED'].includes(value) ? (shipping_states === 'DELIVERED' ? `${shipping_states},BUYER_ACCEPTED` : shipping_states) : UND,
          filter_category: title,
          order_id: UND,
          page: UND,
        },
      })
    },
    changePage(page) {
      this.$router.push({ query: { ...this.$route.query, page } })
    },
    onShowModalFilter() {
      // Reset form modal filter
      this.showModalFilter = true
    },
    resetRow() {
      this.salesData = []
      this.total_row = 0
    },
    getData: debounce(async function() {
      const { path, query, params } = this.$route
      const {
        business_id,
        business_id_sales,
        sales_channel_id,
        warehouse_id,
        q,
        date_from,
        date_until,
        customer_name,
        courier_name,
        ...restQuery
      } = query // , courier_id

      this.loadingList = true
      this.errorRows = false
      this.querySearch = q || ''
      this.showModalFilter = false // Close modal if back/forward button

      if (date_from === 'all' && date_until === 'all') {
        this.orderPeriod = ['all', 'all']
      } else {
        // Initial value from query params
        this.orderPeriod =
          date_from && date_until
            ? [
                this.$moment(date_from).format(),
                this.$moment(date_until).format(),
              ]
            : this.setDefaultOrderPeriod()
        
      }

      this.businessValue = business_id_sales ? business_id_sales.split(',') : []

      this.channelValue =
        path === '/sales' && sales_channel_id ? sales_channel_id.split(',').map(v => +v) : []

      this.warehouseValue = warehouse_id ? warehouse_id.split(',') : []

      this.customerValue = customer_name || ''
      this.courierValue = courier_name || ''

      // Remove business_id_sales params from url
      if (business_id && business_id_sales) {
        this.$router.replace({ query: { ...query, business_id_sales: undefined } }) // push
      }

      this.controller = new AbortController()

      let salesChannelId = sales_channel_id
      let channelId = +params.id || undefined

      if (this.listChannels?.id && path?.startsWith('/redeem-channel') && +params.id !== this.listChannels.id) {
        salesChannelId = this.listChannels.sales_channel.id
        channelId = this.listChannels.id
      }

      await getSales({
        signal: this.controller.signal,
        params: {
          ...restQuery,
          business_id: business_id || business_id_sales || this.businessId,
          page: this.currentPage,
          limit: this.PAGE_SIZE,
          sort_mode: this.sort_mode,
          sales_channel_id: salesChannelId,
          warehouse_id,
          channel_id: channelId,
          q,
          date_from: this.$moment(this.orderPeriod[0]).add(7, 'hours').toISOString(),
          date_until: this.$moment(this.orderPeriod[1]).add(7, 'hours').toISOString(),
          customer_name,
          courier_name,
        },
      })
      .then(({ data: resSales }) => {
        if (resSales) {
          let orderIds = []
          const { data, total_row } = resSales
          this.orderLength = 0

          this.salesData = data && data.length
            ? data.map(d => {
                orderIds.push(d.id)
                this.orderLength = d.order_number && d.order_number.length > this.orderLength
                  ? d.order_number.length
                  : this.orderLength
                if(this.$route.meta.key.includes('sales-channel-kino')) {
                  d.sfa = {
                    order_id: '',
                    created_at: '',
                    response: '',
                    response_body: '',
                    response_code: '',
                  }
                }
                return d
              })
            : []
          this.total_row = total_row
          if(this.$route.meta.key.includes('sales-channel-kino')) {
            this.fetchKinoSfa(orderIds.join())
          }
        } else {
          this.resetRow()
        }
      })
      .catch(err => {
        if (err?.code !== 'ERR_CANCELED') {
          this.errorRows = err.response.status
          this.resetRow()
        }
      })
      .finally(async () => {
        // Get channel data for initial value filter
        if (path === '/sales' && this.loadChannel && sales_channel_id) {
          await this.fetchChannel()
        }

        // Get warehouse data for initial value filter
        if (this.loadWarehouse && warehouse_id) {
          await this.fetchWarehouse()
        }

        this.controller = null
        this.loadingList = false
      })
    }, 500),
    async fetchKinoSfa(orderIds) {
      this.total_box = 0
      await getKinoSfa({ orderIds })
        .then(({ data: { data: response } }) => {
          response?.forEach(sfa => {
            const index = this.salesData.findIndex(sales => sales.id === sfa.order_id)
            if(index > -1) {
              this.salesData[index].sfa = sfa
              if(sfa.response == '0') {
                this.salesData[index].submitted = false
                this.total_box++
              }
            }
          })
        })
    },
    blurChannel() {
      if (!this.options_channel.length && !this.loadChannel) {
        this.loadChannel = true
      }
    },
    fetchChannel: debounce(async function() {
      if (this.loadChannel) {
        const { business_id } = this.$route.query
        await this.$store
          .dispatch('channel/GETSALESCHANNEL', { business_id: business_id || this.businessId })
          .then(() => {
          })
          .catch(err => {
            this.$message.error(err.message)
            document.activeElement.blur()
          })
          .finally(() => {
            this.loadChannel = false
          })
      }
    }, 500),
    onBlurWarehouse() {
      // Reset loading state
      if (!this.warehouse_list.length && !this.loadWarehouse) {
        this.loadWarehouse = true
      }
    },
    fetchWarehouse: debounce(async function() {
      if (this.loadWarehouse) {
        const { business_id } = this.$route.query
        await this.$store
          .dispatch('warehouse/GETSALESWAREHOUSE', {
            business_id: business_id || this.businessId,
          })
          .then(({ data }) => {
            this.warehouse_list = data || []
          })
          .catch((err) => {
            this.$message.error(err.message)
            document.activeElement.blur()
          })
          .finally(() => {
            this.loadWarehouse = false
          })
      }
    }, 500),
    async onOpenCollapse(item) {
      await this.fetchWarehouse()
      this.salesData.forEach(f => {
        if (f.id === item.id) {
          // Get & Set warehouse name
          const nameWarehouse = this.warehouse_list.find(f => f.id === item.warehouse_id)
          if (nameWarehouse) {
            item.warehouseName = nameWarehouse.name
            item.warehouseAddress = nameWarehouse.address
          } else {
            item.warehouseName = '-'
          }
        }
      })
    },
    checkboxSelectAllSubmitted(event) {
      this.checked_all = event.target.checked
      this.salesData.forEach((item, index) => {
        if(this.$route.meta.key.includes('sales-channel-kino') && item?.sfa?.response == '0') {
          this.handleItemSubmitted({
            index,
            checked: event.target.checked,
            id: item.id,
          })
        }
      })
    },
    handleSfaDetail(value) {
      this.sfaInfo = {
        visible: true,
        order_number: value.order_number,
        description: value.response_body,
      }
    },
    handleItemSubmitted(obj) {
      this.salesData[obj.index].submitted = obj.checked
      const index = this.tempSales.findIndex(sales => sales === obj.id)
      if(obj.checked && index < 0) {
        this.tempSales.push(obj.id)
      } else if(!obj.checked && index > -1) {
        this.tempSales.splice(index, 1)
      }
      
      this.indeterminate = this.tempSales.length > 0 && this.tempSales.length < this.total_box
      this.checked_all = this.tempSales.length == this.total_box
    },
    async handleSubmitSFA() {
      this.loadingList = true
      await resendKinoSfa({
        business_id: this.businessId,
        data: { order_ids: this.tempSales },
      })
      .then(() => this.finishSubmitSFA('success', ''))
      .catch(err => this.finishSubmitSFA('failed', err?.response?.data?.message || ''))
    },
    finishSubmitSFA: debounce(function(status, description) {
      const type = status === 'failed' ? 'error' : 'success'
      this.$notification[type]({
        message: `Resend SFA ${this.$t(`utils.${status}`)}`,
        description,
      })
      if(status === 'success') {
        this.indeterminate = false
        this.checked_all = false
        this.tempSales = []
        this.getData()
      } else {
        this.loadingList = false
      }
    },5000),
    refreshPage() {
      window.location.reload()
    },
    onSyncOrderForChannel(payload) {
      this.loadingSyncOrder = true
      const { query, params } = this.$route
      if (['shopee_id', 'lazada_id'].includes(query.channel_code)) {
        onSyncOrderChannel({
          business_id: query.business_id,
          channel_id: params.id,
          channel_code: query.channel_code,
          payload: payload,
        })
        .then(() => {
          let checkTimes = 0
          const interval = setInterval(async () => {
            checkTimes +=1
            const { data: dataCheck } = await checkStatusSyncOrderChannel({
              business_id: query.business_id,
              channel_id: params.id,
              channel_code: query.channel_code,
            })
            if (dataCheck?.data?.status === "COMPLETED") {
              clearInterval(interval)
              this.$notification.success({
                message: 'Berhasil Sync Order',
                description: 'Halaman akan otomatis direfresh saat notification ini hilang',
                onClose: () => this.refreshPage(),
                duration: 3,
              })
              this.loadingSyncOrder = false
              this.onCloseModalSyncOrderChannel()
              setTimeout(() => this.refreshPage(), 3000)
            } else if (checkTimes > 9) {
              clearInterval(interval)
              this.$notification.success({
                message: 'Sync Order anda dalam proses',
                description: 'Maaf, harap menunggu sebentar. permintaan sync order anda dalam proses',
              })
              this.loadingSyncOrder = false
              this.onCloseModalSyncOrderChannel()
            }
          }, 2000)
        })
        .catch(error => {
          const errorRes = error?.response?.data
          this.$notification.error({
            message: 'Terjadi Kesalahan',
            description: errorRes ? `${errorRes.error}, ${errorRes.status} - ${errorRes.message}` : 'Internal Server Error',
          })
          this.loadingSyncOrder = false
          this.onCloseModalSyncOrderChannel()
        })
      } else if (['tokopedia_id', 'tiktok'].includes(query.channel_code)) {
        onSyncOrderChannel({
          business_id: query.business_id,
          channel_id: params.id,
          channel_code: query.channel_code,
          payload: payload,
        })
        .then(({ status, data }) => {
          this.loadingSyncOrder = false
          this.onCloseModalSyncOrderChannel()
          if ([200, 202].includes(status)) {
            this.$notification.success({
              message: 'Berhasil Sync Order',
              description: 'Halaman akan otomatis direfresh saat notification ini hilang',
              onClose: () => this.refreshPage(),
              duration: 3,
            })
            setTimeout(() => this.refreshPage(), 3000)
          } else {
            this.$notification.error({
              message: 'Terjadi Kesalahan',
              description: data?.message || 'Gagal saat melakukan sync order.',
            })
          }
        })
        .catch(error => {
          const errorRes = error?.response?.data
          this.$notification.error({
            message: 'Terjadi Kesalahan',
            description: errorRes ? `${errorRes.error}, ${errorRes.status} - ${errorRes.message}` : 'Internal Server Error',
          })
          this.loadingSyncOrder = false
          this.onCloseModalSyncOrderChannel()
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.row-calc > .col {
  padding-left: 9px;
  padding-right: 9px;
  flex-basis: calc(100% / 6); // 100% / 8
  max-width: calc(100% / 6);
  font-size: 13px;
}

.col-10p {
  flex-basis: 10%;
  max-width: 10%;
  font-size: 13px;
}

.salesindex {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;

  .categories {
    .list {
      color: #999;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 14px;
      min-height: 30px;
      background: #fff;
    }

    .list:not(:focus) {
      border-color: #999;
    }

    .active {
      background: #4d4d4d;
      color: #fff;
      border-color: #4d4d4d;
    }

    .list:hover:not(.active) {
      border-color: #4d4d4d;
      color: #4d4d4d;
    }
  }

  .active {
    background: #0559cb;
    color: #fff;
    border: 1px solid #0559cb;
  }
}
</style>

<style lang="scss">
.skeleton-total-sales ul {
  margin: 0;
}
.skeleton-total-sales li {
  height: 18px !important;
  border: 1px solid #ddd;
}
.w-8 {
  flex-basis: 4% !important;
  max-width: 4% !important;
  -ms-flex-preferred-size: 4% !important;
}
.fulfillment-checkbox .ant-checkbox-inner {
  border-color: #999 !important;
}
.filter-tag {
  padding: 4px 8px !important;
  font-size: 11px !important;
  background: #e8e8e8 !important;
  border-radius: 16px !important;

  i {
    font-size: 14px !important;
  }
}
</style>